import ServiceCatalog from "../../../features/service/components/ServiceCatalog";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {InstallationCatalog} from "../../installation/components/InstallationCatalog";
import { useFetchNetworkById } from '../../network/hooks/useFetchNetworkById'
import { NotFound } from '../../../pages/NotFound/NotFound'
export const Catalog = () => {

    const { id, typecatalog } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const {error: networkError} = useFetchNetworkById(id);

    if (networkError && networkError.status === 404) {
        return <NotFound backString="Browse another catalog" backLink={`/catalog`} />
    }

    if (typecatalog === "services") return <ServiceCatalog isOpen={isOpen} setIsOpen={setIsOpen} limit={16} />

    if (typecatalog === "installations") return <InstallationCatalog isOpen={isOpen} setIsOpen={setIsOpen} limit={16} />
}
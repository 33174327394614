import {useParams} from "react-router-dom";
import {Service} from "../../service/pages/Service";
import {Installation} from "../../installation/page/Installation";
import { useFetchNetworkById } from '../../network/hooks/useFetchNetworkById'
import { NotFound } from '../../../pages/NotFound/NotFound'
import React from 'react'

export const CatalogDescriptif = () => {

    const { id, typecatalog } = useParams();

    const {error: networkError} = useFetchNetworkById(id);

    if (networkError && networkError.status === 404) {
        return <NotFound backString="Browse another catalog" backLink={`/catalog`} />
    }

    if (typecatalog === "services") return <Service type="service"/>

    if (typecatalog === "installations") return <Installation type="installations" />
}